<template>
  <v-container class="fill-height justify-center">
    <v-container>
      <!-- HEADER IMAGE --------------------------------------------------------------------------->
      <v-row justify="center">
        <v-img max-height="150" max-width="300" src="../assets/LicenseServer_TitleText_white.png"></v-img>
      </v-row>
      <v-row justify="center">
        <v-expand-transition>
          <!-- LOGIN 2FA FORM INPUTS -------------------------------------------------------------->
          <validation-observer v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(validateLoginForm)">
              <mex-card color="primary" icon="mdi-login" light max-width="100%" title="Login" width="400">
                <v-row justify="center">
                  <v-col cols="12">
                    <!-- Username ----------------------------------------------------------------->
                    <validation-provider v-slot="{ errors }" name="Username" rules="required">
                      <v-text-field
                        v-model="username"
                        :error-messages="errors"
                        autocomplete="off"
                        class="mt-10"
                        color="secondary"
                        label="Username"
                        prepend-icon="mdi-account"
                        type="text"
                      />
                    </validation-provider>
                    <!-- Password ----------------------------------------------------------------->
                    <validation-provider v-slot="{ errors }" name="Password" rules="required">
                      <v-text-field
                        v-model="password"
                        :error-messages="errors"
                        class="mt-10"
                        color="secondary"
                        label="Password"
                        prepend-icon="mdi-form-textbox-password"
                        type="password"
                      />
                    </validation-provider>
                    <!-- 2FA Code ----------------------------------------------------------------->
                    <validation-provider v-slot="{ errors }" name="2FA Code" rules="required|numeric|max:6|min:6">
                      <v-text-field
                        v-model="tfaToken"
                        :error-messages="errors"
                        class="mt-10"
                        color="secondary"
                        label="2 FA"
                        prepend-icon="mdi-two-factor-authentication"
                        type="text"
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
                <!-- 2FA LOGIN ACTIONS ------------------------------------------------------------>
                <!-- Recovery code login ---------------------------------------------------------->
                <v-row justify="center">
                  <mex-btn color="secondary" content="Use recovery code" plain @click="showRecoveryLogin = true" />
                </v-row>
                <!-- Forgot password -------------------------------------------------------------->
                <v-row justify="center">
                  <mex-btn color="secondary" content="Forgot password" plain @click="showForgotPassword = true" />
                </v-row>
                <!-- Submit Login ----------------------------------------------------------------->
                <v-row justify="center">
                  <mex-btn class="ma-5" content="Login" type="submit" />
                </v-row>
              </mex-card>
            </form>
          </validation-observer>
        </v-expand-transition>
      </v-row>
    </v-container>
    <!-- RECOVERY CODE LOGIN DIALOG --------------------------------------------------------------->
    <mex-dialog
      :acceptOnSubmit="true"
      :showDialog="showRecoveryLogin"
      dialogAcceptButtonTitle="Login"
      dialogRejectButtonTitle="Cancel"
      dialogTitle="Login with 2FA recovery code"
      width="50%"
      @accept="validateRecoveryLoginForm"
      @reject="showRecoveryLogin = false"
    >
      <template v-slot:dialog-content>
        <v-row justify="center">
          <mex-p content="Use one of your recovery codes for a one time authentication." />
        </v-row>
        <v-row justify="center">
          <mex-p
            alignment="center"
            content="Please note that the recovery code used for this purpose can only be used once and is no longer valid thereafter."
            fontWeight="bold"
          />
        </v-row>
        <v-row justify="center">
          <!-- RECOVERY CODE LOGIN FORM INPUTS ---------------------------------------------------->
          <validation-observer v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(validateRecoveryLoginForm)">
              <v-row justify="center">
                <v-col cols="12">
                  <!-- Username ------------------------------------------------------------------->
                  <validation-provider v-slot="{ errors }" name="Username" rules="required">
                    <v-text-field
                      v-model="username"
                      :error-messages="errors"
                      autocomplete="off"
                      class="mt-10"
                      color="secondary"
                      label="Username"
                      prepend-icon="mdi-account"
                      type="text"
                    />
                  </validation-provider>
                  <!-- Password ------------------------------------------------------------------->
                  <validation-provider v-slot="{ errors }" name="Password" rules="required">
                    <v-text-field
                      v-model="password"
                      :error-messages="errors"
                      class="mt-10"
                      color="secondary"
                      label="Password"
                      prepend-icon="mdi-form-textbox-password"
                      type="password"
                    />
                  </validation-provider>
                  <!-- Recovery Code -------------------------------------------------------------->
                  <validation-provider v-slot="{ errors }" name="2FA recovery code" rules="required|numeric|max:11|min:11">
                    <v-text-field
                      v-model="tfaRecoveryCode"
                      :error-messages="errors"
                      class="mt-10"
                      color="secondary"
                      label="2 FA recovery code"
                      prepend-icon="mdi-two-factor-authentication"
                      type="text"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
            </form>
          </validation-observer>
        </v-row>
      </template>
    </mex-dialog>
    <!-- FORGOT PASSWORD DIALOG ____--------------------------------------------------------------->
    <mex-dialog
      :showDialog="showForgotPassword"
      dialogAcceptButtonTitle="Verify Account "
      dialogRejectButtonTitle="Cancel"
      dialogTitle="Reset your password"
      width="30%"
      @accept="resetPasswordSystemUser"
      @reject="showForgotPassword = false"
    >
      <template v-slot:dialog-content>
        <v-row justify="center">
          <mex-p content="Enter your username and email and we will send you a password reset link." />
        </v-row>
        <validation-observer v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(resetPasswordSystemUser)">
            <v-row justify="center">
              <v-col cols="12">
                <!-- Username ------------------------------------------------------------------->
                <validation-provider v-slot="{ errors }" name="Username" rules="required">
                  <v-text-field
                    v-model="username"
                    :error-messages="errors"
                    autocomplete="off"
                    class="mt-10"
                    color="secondary"
                    label="Username"
                    prepend-icon="mdi-account"
                    type="text"
                  />
                </validation-provider>
                <!-- Email ------------------------------------------------------------------->
                <validation-provider v-slot="{ errors }" name="Email" rules="email|required">
                  <v-text-field
                    v-model="email"
                    :error-messages="errors"
                    class="mt-10"
                    color="secondary"
                    label="Email"
                    prepend-icon="mdi-email"
                    type="email"
                  />
                </validation-provider>
              </v-col>
            </v-row>
          </form>
        </validation-observer>
      </template>
    </mex-dialog>
  </v-container>
</template>

<script>
import SystemUsersService from '../services/systemUsers.service';

export default {
  props: {
    /**
     * defines if the view is used for initial login or for login during session unlock
     */
    sessionUnlock: {
      type: Boolean,
      default: false,
      description: '',
    },
  },
  data() {
    return {
      // Visualization
      /** open/close recovery code login dialog */
      showRecoveryLogin: false,
      /** open/close forgot password dialog */
      showForgotPassword: false,
      // Login
      username: '',
      email: '',
      password: '',
      tfaToken: '',
      tfaRecoveryCode: '',
    };
  },
  /**
   * If its used as initial login it logs out the logged in systemUser
   * in the browser
   */
  created() {
    if (!this.sessionUnlock) {
      this.$store.commit('sysAuth/logout');
    }
    SystemUsersService.systemUserLogout();
  },
  methods: {
    validateLoginForm() {
      this.authenticateSystemUser();
    },
    validateRecoveryLoginForm() {
      this.authenticateSystemUserRecoveryCode();
    },
    authenticateSystemUser() {
      this.loginLoading = true;
      SystemUsersService.systemUserAuthenticate(this.username, this.password, this.tfaToken)
        .then((response) => {
          this.successfulLogin(response.data);
        })
        .catch((err) => {
          this.failedLogin(err);
        });
    },
    authenticateSystemUserRecoveryCode() {
      SystemUsersService.systemUserAuthenticateRecovery(this.username, this.password, this.tfaRecoveryCode)
        .then((response) => {
          this.successfulLogin(response.data);
        })
        .catch((err) => {
          this.failedLogin(err);
        });
    },
    successfulLogin(authResponse) {
      SystemUsersService.getUserPermissions(authResponse.SystemUserID)
        .then((response) => {
          this.$store.commit('sysAuth/loginSuccess', { user: authResponse.SystemUserID, userPermissions: response.data.permissions });
          if (!this.sessionUnlock) {
            this.$router.push({ name: 'SystemUserDashboard' });
          } else {
            this.$emit('unlock-session', true);
            this.$store.commit('sysAuth/unlockSession');
          }
        })
        .catch((err) => {
          this.failedLogin(err);
        });
    },
    failedLogin(authError) {
      if (authError.response && authError.response.status === 401) {
        this.$toast.error('Authentication failed');
      } else if (authError.response && authError.response.status === 404) {
        this.$toast.error(authError.response.data);
      } else if (authError.response && authError.response.status === 403) {
        this.$toast.warning(authError.response.data);
      } else {
        this.$toast.error(`System Error. Authentication failed: ${authError}`);
      }
      if (!this.sessionUnlock) {
        this.$store.commit('sysAuth/loginFailure');
      }
    },
    resetPasswordSystemUser() {
      SystemUsersService.sendResetPasswordMail(this.username, this.email)
        .then(() => {
          this.$toast.success('An email to reset your password was sent to your email');
          this.showForgotPassword = false;
        })
        .catch(() => {
          this.$toast.error('Sending email failed. Please try again later');
        })
    },
  },
};
</script>
