var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"fill-height justify-center"},[_c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-img',{attrs:{"max-height":"150","max-width":"300","src":require("../assets/LicenseServer_TitleText_white.png")}})],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-expand-transition',[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.validateLoginForm)}}},[_c('mex-card',{attrs:{"color":"primary","icon":"mdi-login","light":"","max-width":"100%","title":"Login","width":"400"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-10",attrs:{"error-messages":errors,"autocomplete":"off","color":"secondary","label":"Username","prepend-icon":"mdi-account","type":"text"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-10",attrs:{"error-messages":errors,"color":"secondary","label":"Password","prepend-icon":"mdi-form-textbox-password","type":"password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"2FA Code","rules":"required|numeric|max:6|min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-10",attrs:{"error-messages":errors,"color":"secondary","label":"2 FA","prepend-icon":"mdi-two-factor-authentication","type":"text"},model:{value:(_vm.tfaToken),callback:function ($$v) {_vm.tfaToken=$$v},expression:"tfaToken"}})]}}],null,true)})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('mex-btn',{attrs:{"color":"secondary","content":"Use recovery code","plain":""},on:{"click":function($event){_vm.showRecoveryLogin = true}}})],1),_c('v-row',{attrs:{"justify":"center"}},[_c('mex-btn',{attrs:{"color":"secondary","content":"Forgot password","plain":""},on:{"click":function($event){_vm.showForgotPassword = true}}})],1),_c('v-row',{attrs:{"justify":"center"}},[_c('mex-btn',{staticClass:"ma-5",attrs:{"content":"Login","type":"submit"}})],1)],1)],1)]}}])})],1)],1)],1),_c('mex-dialog',{attrs:{"acceptOnSubmit":true,"showDialog":_vm.showRecoveryLogin,"dialogAcceptButtonTitle":"Login","dialogRejectButtonTitle":"Cancel","dialogTitle":"Login with 2FA recovery code","width":"50%"},on:{"accept":_vm.validateRecoveryLoginForm,"reject":function($event){_vm.showRecoveryLogin = false}},scopedSlots:_vm._u([{key:"dialog-content",fn:function(){return [_c('v-row',{attrs:{"justify":"center"}},[_c('mex-p',{attrs:{"content":"Use one of your recovery codes for a one time authentication."}})],1),_c('v-row',{attrs:{"justify":"center"}},[_c('mex-p',{attrs:{"alignment":"center","content":"Please note that the recovery code used for this purpose can only be used once and is no longer valid thereafter.","fontWeight":"bold"}})],1),_c('v-row',{attrs:{"justify":"center"}},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.validateRecoveryLoginForm)}}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-10",attrs:{"error-messages":errors,"autocomplete":"off","color":"secondary","label":"Username","prepend-icon":"mdi-account","type":"text"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-10",attrs:{"error-messages":errors,"color":"secondary","label":"Password","prepend-icon":"mdi-form-textbox-password","type":"password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"2FA recovery code","rules":"required|numeric|max:11|min:11"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-10",attrs:{"error-messages":errors,"color":"secondary","label":"2 FA recovery code","prepend-icon":"mdi-two-factor-authentication","type":"text"},model:{value:(_vm.tfaRecoveryCode),callback:function ($$v) {_vm.tfaRecoveryCode=$$v},expression:"tfaRecoveryCode"}})]}}],null,true)})],1)],1)],1)]}}])})],1)]},proxy:true}])}),_c('mex-dialog',{attrs:{"showDialog":_vm.showForgotPassword,"dialogAcceptButtonTitle":"Verify Account ","dialogRejectButtonTitle":"Cancel","dialogTitle":"Reset your password","width":"30%"},on:{"accept":_vm.resetPasswordSystemUser,"reject":function($event){_vm.showForgotPassword = false}},scopedSlots:_vm._u([{key:"dialog-content",fn:function(){return [_c('v-row',{attrs:{"justify":"center"}},[_c('mex-p',{attrs:{"content":"Enter your username and email and we will send you a password reset link."}})],1),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.resetPasswordSystemUser)}}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-10",attrs:{"error-messages":errors,"autocomplete":"off","color":"secondary","label":"Username","prepend-icon":"mdi-account","type":"text"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Email","rules":"email|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-10",attrs:{"error-messages":errors,"color":"secondary","label":"Email","prepend-icon":"mdi-email","type":"email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)})],1)],1)],1)]}}])})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }